import React from 'react';
import ReactPlayer from 'react-player/lazy';

import styles from './index.module.css';

interface VideoSectionProps {
  title: string;
  videoSrc: string;
  showDoc: (url: string) => void;
  poster?: string;
}

export const NormalSection = ({ title, videoSrc, poster, showDoc }: VideoSectionProps) => {
  return (
    <div className={styles.sectionContainer}>
      <h2 className={styles.title}>{title}</h2>
      <ReactPlayer
        url={videoSrc}
        width='100%'
        height='inherit'
        style={{
          minHeight: 120,
          maxWidth: 800,
          padding: 20,
        }}
        config={{
          file: {
            attributes: {
              controls: true,
              poster: poster,
            },
          },
        }}
      />
    </div>
  );
};
