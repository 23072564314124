import style from './index.module.css';

export const Banner = () => {
  return (
    <div className='banner-content'>
      <h1 className={style.h1}>
        <a>
          <span className={style.name}>杨迪雅</span>
        </a>
        <span> • </span>
        <span className={style.description}>作品集</span>
      </h1>
    </div>
  );
};
