import React from 'react';
import logo from './logo.svg';
import { Button } from 'antd';
import './App.css';
import { Home } from './pages/Home';

function App() {
  return <Home />;
}

export default App;
