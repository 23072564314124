import React, { useEffect, useState } from 'react';
import Layout from 'antd/lib/layout/layout';
import { Banner } from 'components/Banner';
import { Footer } from 'components/Footer';

import styles from './index.module.css';
import { NormalSection } from 'components/NormalSection';

const getPageData = async () => {
  const res = await fetch('//static6.qingrizhi.com/cv/res/pageData.json?' + Date.now());
  return await res.json();
  // return {
  //   sections: [
  //     {
  //       title: 'Alike',
  //       videoSrc: 'http://static6.qingrizhi.com/cv/res/alike%E6%9D%A8%E8%BF%AA%E9%9B%85.mp4',
  //       poster: 'http://static6.qingrizhi.com/cv/res/alike%E6%9D%A8%E8%BF%AA%E9%9B%85-0001.png',
  //     },
  //     // {
  //     //   title: '众神旗帜项目音效展示',
  //     //   videoSrc:
  //     //     'http://static6.qingrizhi.com/cv/res/%E4%BC%97%E7%A5%9E%E6%97%97%E5%B8%9C%E9%A1%B9%E7%9B%AE%E9%9F%B3%E6%95%88%E5%B1%95%E7%A4%BA.mp4',
  //     //   poster:
  //     //     'http://static6.qingrizhi.com/cv/res/%E4%BC%97%E7%A5%9E%E6%97%97%E5%B8%9C%E9%A1%B9%E7%9B%AE%E9%9F%B3%E6%95%88%E5%B1%95%E7%A4%BA-0001.png',
  //     // },
  //     // {
  //     //   title: '守望先锋音效重制',
  //     //   videoSrc:
  //     //     'http://static6.qingrizhi.com/cv/res/%E5%AE%88%E6%9C%9B%E5%85%88%E9%94%8B%E9%9F%B3%E6%95%88%E9%87%8D%E5%88%B6.mp4',
  //     //   poster:
  //     //     'http://static6.qingrizhi.com/cv/res/%E5%AE%88%E6%9C%9B%E5%85%88%E9%94%8B%E9%9F%B3%E6%95%88%E9%87%8D%E5%88%B6-0001.png',
  //     // },
  //     // {
  //     //   title: '鲸歌',
  //     //   videoSrc:
  //     //     'http://static6.qingrizhi.com/cv/res/%E9%B2%B8%E6%AD%8C%20%E6%9D%A8%E8%BF%AA%E9%9B%85.mp4',
  //     //   poster:
  //     //     'http://static6.qingrizhi.com/cv/res/%E9%B2%B8%E6%AD%8C%20%E6%9D%A8%E8%BF%AA%E9%9B%85-0001.png',
  //     // },
  //     {
  //       title: '众神旗帜商城音乐',
  //       videoSrc:
  //         'http://static6.qingrizhi.com/cv/res/%E4%BC%97%E7%A5%9E%E6%97%97%E5%B8%9C%E5%95%86%E5%9F%8E%E9%9F%B3%E4%B9%90.wav',
  //     },
  //   ],
  // };
};

interface PageData {
  sections?: {
    title: string;
    videoSrc: string;
    poster?: string;
  }[];
}

export function Home() {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<PageData>({});
  const [imgUrl, setImgUrl] = useState('');

  const init = async () => {
    const pageData = await getPageData();
    console.log('pageData', pageData);
    setData(pageData);
  };

  useEffect(() => {
    init();
  }, []);

  const showDoc = (url: string) => {
    console.log('设置 imgurl:', url);
    setImgUrl(url);
    setVisible(true);
  };

  const sectionList = data.sections?.map((section, idx) => {
    return (
      <NormalSection
        key={idx}
        title={section.title}
        videoSrc={section.videoSrc}
        poster={section.poster}
        showDoc={showDoc}
      />
    );
  });

  return (
    <Layout className={styles.homeContainer}>
      <Banner />
      {sectionList}
      <Footer />
    </Layout>
  );
}
